<template>
  <div>
    <v-main>
      Vertrieb
    </v-main>
  </div>
</template>

<script>
export default {
  name: "Sales"
};
</script>

<style scoped>

</style>